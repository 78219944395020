
import GoogleIcon from "@/assets/images/google-icon.svg";
import { defineComponent, ref } from "vue";
import { AuthProviderSignInButton } from "@/components/cc/AuthProviderSignInButton";
import { MdInputField, MdInputFieldWrapper } from "@/components/md/MdInput";
import { MdButton } from "@/components/md/MdButton";
import { MdProgressIndicatorCircular } from "@/components/md/MdProgressIndicator";
import { TransitionFade } from "@/components/cc/Transition";
import { InputValidator, InputType } from "@/classes/InputValidator";
import {
  getAuth,
  signInWithEmailAndPassword,
  AuthError,
  AuthErrorCodes,
} from "firebase/auth";
import { useStore } from "@/store";
import { Actions } from "@/store/modules/user/actions";
import { UserStore } from "@/store/constants";

export default defineComponent({
  name: "Login",
  components: {
    AuthProviderSignInButton,
    MdInputFieldWrapper,
    MdInputField,
    MdButton,
    MdProgressIndicatorCircular,
    TransitionFade,
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  data() {
    return {
      email: "",
      password: "",
      GoogleIcon,
      overlayVisible: false,
    };
  },
  computed: {
    isSignedIn(): boolean {
      return this.$store.getters[UserStore.Getters.IS_SIGNED_IN];
    },
    emailValidator(): InputValidator {
      return new InputValidator(this.email, InputType.EMAIL, true);
    },
    passwordValidator(): InputValidator {
      return new InputValidator(this.password, InputType.PASSWORD, true);
    },
    emailField(): typeof MdInputField {
      return this.$refs.email as typeof MdInputField;
    },
    passwordField(): typeof MdInputField {
      return this.$refs.password as typeof MdInputField;
    },
  },
  watch: {
    isSignedIn(value) {
      this.$router.push("/");
    },
  },
  methods: {
    isFormValid(): boolean {
      this.emailField.validate();
      this.passwordField.validate();
      return this.emailValidator.isValid() && this.passwordValidator.isValid();
    },
    async signIn(event) {
      if (event) event.preventDefault();
      if (!this.isFormValid()) return;
      this.overlayVisible = true;
      try {
        await signInWithEmailAndPassword(getAuth(), this.email, this.password);
        const user = getAuth().currentUser;
        if (user) {
          this.store.dispatch(Actions.SIGN_IN, user);
          this.$router.push("/");
        }
      } catch (err) {
        this.password = "";
        const code = (err as AuthError).code;
        switch (code) {
          case AuthErrorCodes.USER_DELETED:
            this.emailField.setErrorMessage(
              "Zu dieser E-Mail-Adresse wurde kein Account gefunden."
            );
            break;
          case AuthErrorCodes.INVALID_PASSWORD:
            this.passwordField.setErrorMessage("Das Passwort ist falsch.");
            break;
          default:
            console.error("error: ", err);
            var message = "Es ist ein unbekanntes Problem aufgetreten.";
            this.emailField.setErrorMessage(message);
            this.passwordField.setErrorMessage(message);
        }
        this.overlayVisible = false;
      }
    },
  },
});
